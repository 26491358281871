<template>
    <div class="container-reservations">
      <div class="action-buttons">
        <button :class="{ 'active-button': activeActionButton === 'reservations' }"
          @click="setActiveActionButton('reservations')">Бронирования</button>
        <button :class="{ 'active-button': activeActionButton === 'promocodes' }"
          @click="setActiveActionButton('promocodes')">Промокоды</button>
        <button :class="{ 'active-button': activeActionButton === 'bonuses' }"
          @click="setActiveActionButton('bonuses')">Статистика</button>
          <button :class="{ 'active-button': activeActionButton === 'payouts' }"
          @click="setActiveActionButton('payouts')">Выплаты</button>
        <button :class="{ 'active-button': activeActionButton === 'settings' }"
          @click="setActiveActionButton('settings'); setPhoneMask()">Настройки</button>
        <button @click="exitUser()">Выйти</button>
      </div>
      <div class="discount-system" v-if="activeActionButton === 'reservations'">
        <div class="discounts">
          <p class="discounts-heading">Зарабатывайте до 10% с каждого бронирования!</p>
          <p>1. Зарегистрируйтесь как Агент</p>
          <p>2. Создайте промокод с персональной скидкой до 10%*</p>
          <p>3. Зарабатывайте до 10% с каждого бронирования с применением персонального промокода* </p>

          <p class="mt-2" style="font-size: 0.8em; width: 80%;">
            *Ваша комиссия 10% с каждой сделки. Вы можете делиться частью комиссии (от 1% до 10%), чтобы мотивировать гостей пользоваться вашим промокодом. <br>
            Пример: вы создаете промокод с персональной скидкой 3%. В таком случае вы получаете комиссию в 7% от изначальной полной суммы бронирования, а гость получает скидку 3% на бронирование.
        </p>
        </div>
        <!-- <div class="present">
          <img src="../assets/img/present.png" alt="">
        </div> -->
      </div>
      <div class="reserved-hotels" v-if="activeActionButton === 'reservations'">
        <div class="reservations-buttons">
          <button :class="{ 'active-button': activeReservationButton === 'all' }"
            @click="setActiveReservationButton('all')">Все</button>
          <button :class="{ 'active-button': activeReservationButton === 'reserved' }"
            @click="setActiveReservationButton('reserved')">Действительные</button>
          <button :class="{ 'active-button': activeReservationButton === 'archive' }"
            @click="setActiveReservationButton('archive')">В архиве</button>
          <button :class="{ 'active-button': activeReservationButton === 'canceled' }"
            @click="setActiveReservationButton('canceled')">Отмененные</button>
        </div>
        <div class="reservations-hotels">
          <ReservationHotel v-for="(hotel, index) in filteredHotels" :key="index" :act="hotel.act" :mail="hotel.mail" :status="hotel.status" :res_id="hotel.id" :reserved="hotel.reserved"
            :image="hotel.image" :name="hotel.name" :bed="hotel.bed"
            :eatAvailability="hotel.eatAvailability" :wifiAviability="hotel.wifiAviability" :price="hotel.price"
            :nights="Number(hotel.nights)" :guests="Number(hotel.guests)" :dateCheckIn="hotel.dateCheckIn"
            :dateCheckOut="hotel.dateCheckOut" :id="hotel.room_id" :address="hotel.address" :date="hotel.date" :agent_sale="hotel.agent_sale"/>
        </div>
      </div>
      <div class="container-bonuses" v-if="activeActionButton === 'bonuses'">
        <div class="main-block">
          <div class="stats-block">
            <p>Статистика</p>
            <div class="stats" v-bind:data-orders="orders" v-bind:data-discount="balance">
              <div class="stat-order">
                <p>Всего заказов</p>
                <p class="orders"> {{ orders }} </p>
              </div>
              <div class="stat-discount">
                <p>Баланс</p>
                <p class="discount">{{ balance }} руб.</p>
              </div>
            </div>
          </div>
          <div class="programm-block">
            <p>Описание программы</p>
            <div class="programm-bonuses">
            <p>1. Зарегистрируйтесь как <span>Агент</span></p>
            <p>2. Создайте промокод с персональной <span>скидкой до 10%</span>*</p>
            <p>3. Зарабатывайте до 10% с каждого бронирования <span>с применением персонального промокода</span>* </p>

            <p class="mt-2" style="font-size: 0.8em; width: 80%;">
                *Ваша комиссия 10% с каждой сделки. Вы можете <span>делиться частью комиссии</span> (от 1% до 10%), чтобы мотивировать гостей пользоваться вашим промокодом. <br>
                Пример: вы создаете промокод с персональной скидкой 3%. В таком случае <span>вы получаете комиссию в 7%</span> от изначальной полной суммы бронирования, а <span>гость получает скидку 3%</span> на бронирование.
            </p>
            </div>
          </div>
        </div>
        <div class="discount-details">
          <p>Операции</p>
          <div class="details">
            <div class="detail" v-for="(detail, index) in details" :key="index">
              <div class="date">
                <p>{{ detail.date }}</p>
              </div>
              <div class="discount">
                <p :class="{'text-success': detail.type == 'Пополнение', 'text-danger': detail.type == 'Списание'}"><span v-if="detail.type=='Пополнение'">+</span><span v-if="detail.type=='Списание'">-</span>{{ detail.sum.toLocaleString("ru") }} руб.</p>
              </div>
              <div class="for-what">
                <p> {{ detail.operation }} </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-settings" v-if="activeActionButton === 'settings'">
        <div class="settings-block">
          <div class="about-you-block">
            <p>Личные данные</p>
            <div class="about-you-settings">
              <div class="your-name">
                <p>Имя: </p>
                <input type="text" name="" id="" placeholder="Ваше имя" required class="input-field" v-model="userInfo.name">
              </div>
              <div class="your-surname">
                <p>Фамилия: </p>
                <input type="text" name="email" placeholder="Ваша фамилия" required class="input-field" v-model="userInfo.surname">
              </div>
              <div class="your-mail">
                <p>Электронный адрес: </p>
                <input type="email" id="email" name="mail" placeholder="E-mail" required class="input-field" v-model="userInfo.mail">
              </div>
              <div class="your-country">
                <p>Телефон: </p>
                <div class="country-input">
                  <input type="tel" id="phone" name="phone" placeholder="Номер телефона" required class="input-field" v-model="userInfo.phone">
                </div>
              </div>
              <div class="your-city">
                <p>Паспорт: </p>
                <input type="text" name="" id="" placeholder="Серия и номер паспорта" required class="input-field" v-model="userInfo.licence">
              </div>

              <div class="your-name" v-if="userInfo.company_name !== ''">
                <p>Наименование организации: </p>
                <input type="text" name="" id="" placeholder="Наименование огранизации" required class="input-field" v-model="userInfo.company_name">
              </div>
              <div class="your-name" v-if="userInfo.address !== ''">
                <p>Адрес: </p>
                <input type="text" name="" id="" placeholder="Адрес" required class="input-field" v-model="userInfo.address">
              </div>
              <div class="your-name" v-if="userInfo.inn_kpp !== ''">
                <p>ИНН/КПП: </p>
                <input type="text" name="" id="" placeholder="ИНН/КПП" required class="input-field" v-model="userInfo.inn_kpp">
              </div>
              <button class="save-settings" @click="editProfile">Сохранить</button>
            </div>
          </div>
          <div class="password-block">
            <p>Изменить пароль</p>
            <div class="password">
              <input type="password" name="" id="main-password" placeholder="Текущий пароль" required class="input-field" v-model="newPassword.old">
              <input type="password" name="" id="new-password" placeholder="Новый пароль" required class="input-field" v-model="newPassword.new">
              <input type="password" name="" id="repeat-new-password" placeholder="Новый пароль еще раз" required class="input-field" v-model="newPassword.newAccept">
              <button @click="changePassword">Изменить пароль</button>
            </div>
          </div>
        </div>
      </div>

      <div class="container-settings container-bonuses" v-if="activeActionButton === 'payouts'">
        <div class="settings-block">
          <div class="about-you-block">
            <p>Создать заявку на выплату</p>
            <p style="font-size: 1em; font-weight: 400;" class="text-danger" v-if="payouts.some(elem => elem.status == 'На проверке')">У вас есть заявка на выплату со статусом "На проверке", после ее рассмотрения вы можете подать новую заявку</p>
            <div class="about-you-settings" v-else>
              <div class="your-name">
                <p>Сумма выплаты (руб.): </p>
                <input type="text" @change="payoutSumCheck()" :class="{'is-invalid': !(newPayout.sum >= 0 && newPayout.sum <= balance)}" placeholder="Введите сумму выплаты" required class="input-field" style="max-width: 250px; padding-left: 10px;" v-model="newPayout.sum">
              </div>
              <div class="your-name">
                <p>Реквизиты: </p>
                <textarea :class="{'is-invalid': !(newPayout.sum >= 0 && newPayout.sum <= balance)}" placeholder="Введите реквизиты для платежа" required style="padding: 10px; max-width: 250px; width: 100%; border: 1px solid #ADADAD; border-radius: 5px;" class="input-field" v-model="newPayout.details" rows="5"></textarea>
              </div>
              <button class="save-settings mt-2" @click="addPayout">Отправить</button>
            </div>
          </div>

          <div class="discount-details">
            <p>История выплат</p>
            <div class="details">
                <div class="detail" v-for="(payout, index) in payouts" :key="index">
                <div class="date">
                    <p>{{ payout.date }}</p>
                </div>
                <div class="date">
                    <p :class="{
                      'text-secondary': payout.status == 'На проверке',
                      'text-danger': payout.status == 'Отклонено',
                      'text-success': payout.status == 'Выполнено'
                    }">{{ payout.status }}</p>
                </div>
                <div class="discount">
                    <p>{{ payout.sum }} руб.</p>
                </div>
                <div class="for-what" title="Нажмите, чтобы скопировать промокод">
                    <p> {{ payout.details }} </p>
                </div>
                </div>
            </div>
            </div>
          
        </div>
      </div>

      <div class="container-settings container-bonuses" v-if="activeActionButton === 'promocodes'">
        <div class="settings-block">
          <div class="about-you-block">
            <p>Создать промокод</p>
            <div class="about-you-settings">
              <div class="your-name">
                <p>Размер скидки гостя (%): </p>
                <!-- <input type="text" :class="{'is-invalid': !(newPromocode.sale >= 0 && newPromocode.sale <=10)}" placeholder="Введите число (от 0 до 10)" required class="input-field" min="0" max="10" style="max-width: 250px; padding-left: 10px;" v-model="newPromocode.sale"> -->
                <select class="form-select" v-model="newPromocode.sale" style="width: 70px;">
                  <option v-for="sale in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">{{ sale }}</option>
                </select>
              </div>
              <button class="save-settings mt-2" @click="addPromocode">Создать</button>
            </div>
          </div>

          <div class="discount-details">
            <p>Ваши промокоды</p>
            <div class="details">
              <div class="text-danger text-center my-5" v-if="promocodes.length == 0">Промокодов не найдено</div>
                <div class="detail" v-for="(promocode, index) in promocodes" :key="index">
                <div class="date">
                    <p>{{ promocode.date }}</p>
                    <p class="text-danger" style="font-size: 0.8em;">Заканчивается через {{ expiresTime(promocode.date) }} ч.</p>
                </div>
                <div class="discount">
                    <p>Скидка: {{ promocode.sale }}%</p>
                </div>
                <div class="for-what" title="Нажмите, чтобы скопировать промокод">
                    <p @click="copyToClickboard(promocode.code)"  style="text-decoration: underline; cursor: pointer;"> {{ promocode.code }} </p>
                </div>
                </div>
            </div>
            </div>
          
        </div>
      </div>

    </div>
  </template>
  
  <script>
  import ReservationHotel from '@/components/ReservationHotel.vue';
  import axios from 'axios';
  
  export default {
    components: {
      ReservationHotel,
    },
    data() {
      return {
        details: [
          {
            date: '07.08.2024',
            sum: 300,
            operation: 'Комиссия за бронирование',
            type: 'Пополнение'
          },
          {
            date: '09.09.2024',
            sum: 500,
            operation: 'Комиссия за бронирование',
            type: 'Пополнение'
          },
          {
            date: '15.09.2024',
            sum: 1000,
            operation: 'Комиссия за бронирование',
            type: 'Пополнение'
          },
          {
            date: '15.09.2024',
            sum: 1800,
            operation: 'Вывод средств с баланса',
            type: 'Списание'
          }
        ],
        orders: 0,
        balance: 0,
        promocodes: [],
        payouts: [],
        ReservationHotels: [
         
        ],
        filteredHotels: [],
        activeReservationButton: 'all',
        activeActionButton: 'reservations',
        userInfo: {
          'id': 0,
          'name': '',
          'surname': '',
          'mail': '',
          'phone': '',
          'licence': '',
          'password': '',
          'role': '',
          'sale': 0,
          'company_name': '',
          'address': '',
          'inn_kpp': ''
        },
        newPassword: {
          old: '',
          new: '',
          newAccept: ''
        },
        newPromocode: {
            sale: 0,
            agent: this.$store.state.user_info.id
        },
        newPayout: {
          sum: 0,
          details: '',
          agent: this.$store.state.user_info.id
        }
      }
    },
    // mounted() {
    //   this.showAll(); // Показать все отели по умолчанию
    // },
    methods: {
      showAll() {
        this.filteredHotels = this.ReservationHotels;
      },
      showReserved(reserved) {
        this.filteredHotels = this.ReservationHotels.filter(hotel => {
          if (reserved === null) {
            return hotel.reserved === null; // В архиве
          } else {
            return hotel.reserved === reserved; // Действительные или Отмененные
          }
        });
      },
      setActiveReservationButton(button) {
        this.activeReservationButton = button; // Устанавливаем новую активную кнопку
  
        // Вызываем соответствующий метод фильтрации
        if (button === 'all') {
          this.showAll();
        } else if (button === 'reserved') {
          this.showReserved(true);
        } else if (button === 'archive') {
          this.showReserved(null);
        } else if (button === 'canceled') {
          this.showReserved(false);
        }
      },
      setActiveActionButton(button) {
        this.activeActionButton = button; // Устанавливаем новую активную кнопку
      },
  
      editProfile(){
        if(confirm('Вы точно хотите изменить персональные данные?')){
          axios.post('/api/edit_user.php', this.userInfo).then((response)=>{
            if(response.data == 1){
              this.$store.commit('updateUser', this.userInfo)
              alert('Успешно')
              window.location.reload()
            }
            else{
              alert('Пользователь с таким Email уже существует!')
            }
          })
        }
      },
  
      changePassword(){
        if(this.newPassword.old == this.$store.state.user_info.password && this.newPassword.new == this.newPassword.newAccept && this.newPassword.new.length > 0){
          if(confirm('Вы точно хотите изменить пароль?')){
            this.newPassword.id = this.$store.state.user_info.id
            axios.post('/api/change_password.php', this.newPassword).then((response)=>{
              if(response.status == 200){
                this.$store.commit('updatePassword', this.newPassword.new)
                alert('Успешно')
                window.location.reload()
              }
            })
          }
        }
        else{
          this.newPassword = {old: '', new: '', newAccept: ''}
          alert('Старый пароль введен неверно или пароли не совпадают, проверьте данные и повторите попытку!')
        }
      },
  
      setPhoneMask(){
        setTimeout(()=>{IMask(document.getElementById('phone'), {
          mask: '+{7}(000)000-00-00'
        });
        },
        1000
        )
      },
  
      loadData(){
        // axios.post('/api/get_user_data.php', {id: this.$store.state.user_info.id, mail: this.$store.state.user_info.mail}).then((response)=>{
        //   console.log(response.data)
        //   this.orders = response.data.length;
        // })
  
        axios.post('/api/get_reservations.php', {agent: this.$store.state.user_info.id}).then((response)=>{
          console.log(response.data)
          for(let i=0; i<response.data.length; i++){
            response.data[i].nights = Number((new Date(response.data[i].dateCheckOut) - new Date(response.data[i].dateCheckIn)) / (1000 * 60 * 60 * 24));
            if(response.data[i].status == 0) response.data[i].reserved = false
            else if(new Date(response.data[i].dateCheckOut) < new Date()) response.data[i].reserved = null
            else response.data[i].reserved = true
            response.data[i].name = response.data[i].title + ' ' + response.data[i].hotel_title
            if(response.data[i].wifiAviability == '1') response.data[i].wifiAviability = true
            else response.data[i].wifiAviability = false
            if(response.data[i].eatAvailability == '1') response.data[i].eatAvailability = true
            else response.data[i].eatAvailability = false
          }
          this.orders = response.data.length
          this.ReservationHotels = response.data
          this.showAll(); // Показать все отели по умолчанию
        })

        axios.post('/api/get_agent_info.php', {id: this.$store.state.user_info.id}).then((response)=>{
            this.balance = response.data[0]
            this.details = response.data[1]
            this.payouts = response.data[2]
            this.promocodes = response.data[3]

            console.log(response.data)
        })
      },
  
      exitUser(){
        this.$store.commit('exitUser');
        window.location.href = '/auth?role=agent'
      },

      addPromocode(){
        if(this.newPromocode.sale >= 0 && this.newPromocode.sale <=10){
            axios.post('/api/add_promocode.php', this.newPromocode).then((response)=>{
                if(response.status == 200){
                    this.promocodes = response.data
                    this.newPromocode.sale = 0
                }
                else{
                    alert('Произошла ошибка, повторите попытку!')
                    this.newPromocode.sale = 0
                }
            })
        }
        else{
            alert('Введите значение от 0 до 10!')
            this.newPromocode.sale = 0
        }
      },

      copyToClickboard(text){
        return navigator.clipboard.writeText(text);
      },

      addPayout(){
        if(this.newPayout.sum[0] == '0' && this.newPayout.sum !== '0') this.newPayout.sum = this.newPayout.sum.substr(1) 

        if(this.newPayout.sum >= 0 && this.newPayout.sum <= this.balance){
            axios.post('/api/add_payout.php', this.newPayout).then((response)=>{
                if(response.status == 200){
                    this.payouts = response.data
                    this.newPayout.sum = 0
                    this.newPayout.details = ''
                }
                else{
                    alert('Произошла ошибка, повторите попытку!')
                    this.newPayout.sum = 0
                    this.newPayout.details = ''
                }
            })
        }
        else{
            alert('Введите корректную сумму выплаты!')
            this.newPayout.sum = 0
            this.newPayout.details = ''
        }
      },

      payoutSumCheck(){
        if(this.newPayout.sum[0] == '0' && this.newPayout.sum !== '0') this.newPayout.sum = this.newPayout.sum.substr(1) 
      },

      expiresTime(promocodeDate){
        const currentDate = new Date(promocodeDate);
        currentDate.setDate(currentDate.getDate() + 2);
        let timeDiff = '';
        timeDiff = (currentDate - new Date())/(1000 * 60 * 60)
        return parseInt(timeDiff)
      }
  
    },
  
    created(){
      if(this.$store.state.user == false){
        alert('Войдите в личный кабинет!')
        window.location.href = '/auth?role=agent'
      }
      else if(this.$store.state.user_info.access == 0){
        alert('Доступ закрыт! Ожидайте выдачи доступа модератором!')
        this.$store.commit('exitUser')
        window.location.href = '/auth?role=agent'
      }
      this.userInfo = this.$store.state.user_info
      this.loadData();
    }
  }
  </script>