<template>
    <div class="reserved-hotel">
        <div class="hotel-image">
            <img :src="`https://admin.moshot.ru/api/images_mini_rooms/${id}/${image}`" alt="Hotel Image">
        </div>
        <div class="hotel-info">
            <div class="hotel-description">
                <p class="hotel-name"> {{ name }} </p>
                <p> {{ address }} </p>
                <!-- <p> {{ bed }}</p> -->
            </div>
            <div class="hotel-service">
                <a v-if="act !== 0 && status > 0" :href="act" target="_blank">Скачать акт выполненных работ</a>
                <div class="hotel-service-pay">
                    <img src="../assets/img/card.png" alt="Payment">
                    <p>Оплата на сайте </p>
                </div>
                <div class="hotel-service-eat">
                    <img src="../assets/img/eat.png" alt="Breakfast">
                    <p>Завтрак <span v-if="eatAvailability === false"> не </span> включен </p>
                </div>
                <div class="hotel-service-wifi">
                    <img src="../assets/img/wifi.png" alt="Wi-Fi">
                    <p><span v-if="wifiAviability === true"> Бесплатный </span> <span v-if="wifiAviability === false">
                            Не бесплатный </span> Wi-Fi</p>
                </div>
            </div>
        </div>
        <div class="hotel-price-and-date">
            <div class="reserve-date my-0">
                <p style="display: inline;">Дата бронирования: </p>
                <p style="display: inline;"> {{ formatDate(new Date(date)) }}</p>
            </div>
            <div class="reserve-date mb-0">
                <p>Даты заезда и выезда:</p>
                <p> {{ formatDate(new Date(dateCheckIn)) }}–{{ formatDate(new Date(dateCheckOut)) }}</p>
            </div>
            <div class="hotel-price d-flex justify-content-end" v-if="agent_sale > 0 && this.$store.state.user_info.role == 'agent'">
                <p style="font-size: 1.2em; text-decoration: line-through;" class="text-danger"> {{ Number(price*100/(100-agent_sale)).toLocaleString('ru') }}₽</p>
                <p style="font-size: 1em;" class="text-danger me-2">(-{{ agent_sale }}%)</p>
                <p>{{ Number(price).toLocaleString('ru') }}₽</p>
            </div>
            <div class="hotel-price" v-else>
                <p> {{ Number(price).toLocaleString('ru') }}₽</p>
            </div>
            <div class="hotel-sleep">
                <p> {{ nights }} <span v-if="nights == 1">ночь</span><span v-if="nights < 5 && nights !== 1">
                        ночи</span><span v-if="nights >= 5"> ночей</span><span v-if="guests > 0">, {{ guests }} <span v-if="guests == 1"> гость
                    </span> <span v-if="guests < 5 && guests !== 1"> гостя </span> <span v-if="guests >= 5"> гостей
                    </span></span></p>
                
                <div class="reservations-buttons justify-content-lg-end pb-3" v-if="status == 1 && reserved == true && mail == $store.state.user_info.mail">
                    <button @click="cancelReservation()">Отменить бронирование</button>
                </div>
            </div>
        </div>
        <div class="reserved-true" v-if="status == 1 ">
            <p>Активно</p>
        </div>
        <div class="reserved-false" v-if="status == 0">
            <p>Отменен</p>
        </div>
        <div class="reserved-in-process" v-if="reserved === null">
            <p>Закрыто</p>
        </div>
        <div class="reserved-in-process" v-if="status == 2">
            <p>Подтверждение отмены</p>
        </div>

    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        reserved: Boolean,
        image: String,
        name: String,
        bed: String,
        eatAvailability: Boolean,
        wifiAviability: Boolean,
        price: String,
        nights: Number,
        guests: Number,
        dateCheckIn: String,
        dateCheckOut: String,
        id: Number,
        address: String,
        agent_sale: Number,
        date: String,
        res_id: Number,
        status: Number,
        mail: String,
        act: String
    },
    methods: {
        getImageSrc(image) {
            try {
                return require(`@/assets/img/${image}`);
            } catch (error) {
                throw new Error(error);
            }
        },
        formatDate(date) {
            var dd = date.getDate();
            if (dd < 10) dd = '0' + dd;

            var mm = date.getMonth() + 1;
            if (mm < 10) mm = '0' + mm;

            var yy = date.getFullYear()

            return dd + '.' + mm + '.' + yy;
        },

        cancelReservation(){
            if(confirm(`Вы уверены что хотите отменить бронирование? После отмены вам вернется ${this.calcCancelSum(this.$props.date, this.$props.dateCheckIn)} рублей.

При аннулировании бронирования возврат оплаченных денежных средств  производится в следующем порядке:
- при направлении заявки на отмену бронирования не менее, чем за 7 дней до заезда, возврат осуществляется в 100% размере
- в случае отмены бронирования в срок от 3 до 6 дней до даты заезда, возврат осуществляется в размере 50%
- в случае отмены брони менее, чем за 3 дня до даты заезда оплаченные денежные средства возврату не подлежат.`))[
                axios.post('/api/cancel_reservation.php', {id: this.$props.res_id, cancel_sum: this.calcCancelSum(this.$props.date, this.$props.dateCheckIn)}).then(()=>{
                    alert('Заявка на отмену бронирования отправлена! Ожидайте подтверждения')
                    window.location.reload()
                })
            ]
        },

        calcCancelSum(date1, date2, sum = this.$props.price){
            let datesDiff = (new Date(date2) - new Date(date1)) / (1000 * 60 * 60 * 24)+1;

            switch(true){
                case datesDiff >= 7:
                    return sum
                case datesDiff <= 6 && datesDiff >=3:
                    return parseInt(sum/2)
                default:
                    return 0
            }
        }
    }
}
</script>