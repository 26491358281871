<template>
  <div class="container">
        <form class="auth-block container-find" id="auth-form" style="padding-bottom: 100px;" @submit.prevent="sendData()">

          <h2>Регистрация</h2>

          <p style="font-size: 0.8em; text-align: center;">У вас есть личный кабинет? <br> <a :href="`/auth?role=${$route.query.role}`" style="color: #000 !important;">Авторизуйтесь сейчас</a></p>

            <div class="form-floating">
              <input type="text" class="form-control mt-3" id="name-booking" required v-model="form_data.name">
              <label for="name-booking">Имя<span class="text-danger">*</span></label>
            </div>

            <div class="form-floating">
              <input type="text" class="form-control mt-3" id="surname-booking" required v-model="form_data.surname">
              <label for="surname-booking">Фамилия<span class="text-danger">*</span></label>
            </div>

            <div class="form-floating">
              <input type="email" class="form-control mt-3" id="mail-booking" required v-model="form_data.mail">
              <label for="mail-booking">E-mail<span class="text-danger">*</span></label>
            </div>

            <div class="form-floating">
              <input type="text" class="form-control mt-3" id="phone-booking" required v-model="form_data.phone">
              <label for="phone-booking">Номер телефона<span class="text-danger">*</span></label>
            </div>

            <div class="form-floating" v-if="$route.query.role !== 'agent'">
              <input type="text" class="form-control mt-3" id="licence-booking" required v-model="form_data.licence">
              <label for="licence-booking">Серия, номер паспорта</label>
            </div>

            <div class="form-floating">
              <input type="password" class="form-control mt-3" id="form-password" required v-model="form_data.password">
              <label for="form-password">Придумайте пароль<span class="text-danger">*</span></label>
            </div>

            <p style="font-size: 0.8em; margin-top: 30px; text-align: center;">Вы юридическое лицо?<br> Заполните информацию ниже </p>

            <div class="form-floating">
              <input type="text" class="form-control mt-3" id="company-name-booking" required v-model="form_data.company_name">
              <label for="company-name-booking">Наименование огранизации<span class="text-danger">*</span></label>
            </div>

            <div class="form-floating">
              <input type="text" class="form-control mt-3" id="company-address-booking" required v-model="form_data.address">
              <label for="company-address-booking">Адрес<span class="text-danger">*</span></label>
            </div>

            <div class="form-floating">
              <input type="text" class="form-control mt-3" id="company-inn-booking" required v-model="form_data.inn_kpp">
              <label for="company-inn-booking">ИНН/КПП<span class="text-danger">*</span></label>
            </div>

            <div class="hotel-booking-button-modal mt-3">
              <button>Зарегистрироваться</button>
            </div>
        </form>

  </div>

</template>

<script>
import axios from 'axios';


export default{
  data(){
    return {
      form_data: {
        role: this.$route.query.role
      }
    }
  },
  methods: {
    sendData(){
      axios.post('/api/reg.php', this.form_data).then((response)=>{
        console.log(response.data)
        if(response.data == 1){
          alert('Регистрация прошла успешно!')
          let mess = `\*Ура, у нас новая регистрация!\*\n${this.form_data.name}\n${this.form_data.surname}\n${this.form_data.mail}\n${this.form_data.phone}\n${this.form_data.licence}`;
            sendTGMessage(mess)
            function sendTGMessage(message){
              const telegramBotToken = '7794052155:AAEx9Y2QCAssqDKCSO4RHXuf0jQsp5GdQNQ';
              const chatId = '-1002425675515';
              let data = {
                  chat_id: chatId,
                  text: message
              }
              axios.post(`https://api.telegram.org/bot${telegramBotToken}/sendMessage`, data).then(()=>{
                window.location.href = '/auth?role=' + this.role
              })
            }

        }
        else{
          alert('Пользователь с таким Email уже существует, повторите попытку!')
        }
      })
    }
  },

  created(){
    if(this.$store.state.user == true && this.$store.state.user_info.role == 'user' && this.$route.query.role == 'user') window.location.href='/lk' 
    else if(this.$store.state.user == true && this.$store.state.user_info.role == 'agent' && this.$route.query.role == 'agent') window.location.href='/lk_agent' 
  },
  mounted(){
    IMask(document.getElementById('phone-booking'), {
        mask: '+{7}(000)000-00-00'
    });
  }
}
</script>